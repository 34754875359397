import React from "react";

import AddCircleIcon from "./add-circle-icon";
import SettingsIcon from "./settings-icon";
import SelectIcon from "./select-icon";
import TrashIcon from "../../components/icons/trash-icon";
import PenIcon from "../../components/icons/pen-icon";
import UsersIcon from "../../components/icons/users-icon";
import AccountIcon2 from "./v2/account2-icon";
import UploadIcon from "../../components/icons/upload-icon";
import DownloadIcon from "../../components/icons/download-icon";
import CategoryIcon from "../../components/icons/category-icon";
import FilterIcon from "../../components/icons/filter-icon";
import CloseIcon from "../../components/icons/close-icon";
import ArrowCircleIcon from "../../components/icons/arrow-circle-icon";
import DeleteIcon from "../../components/icons/delete-icon";
import DocumentIcon from "../../components/icons/document-icon";
import ArrowLeftSmall from "../../components/icons/arrow-left-small";
import ArrowLeftDefault from "../../components/icons/arrow-left";
import ArrowRightDefault from "../../components/icons/arrow-right";
import ArrowRightSmall from "../../components/icons/arrow-right-small";
import ArrowNext from "../../components/icons/arrow-next";
import InfoIcon from "../../components/icons/v2/info-icon";
import PrintIcon from "../../components/icons/v2/print-icon";
import CheckIcon from "../../components/icons/check-icon";
import CalendarIcon from "../../components/icons/calendar-icon";
import MenuDotIcon from "../../components/icons/v2/menu-dot-icon";
import CameraIcon from "../../components/icons/camera-icon";
import { Loader } from "@aws-amplify/ui-react";
import DuplicateIcon from "./duplicate-icon";
import AddDefaultIcon from "./add-default-icon";
import ReturnIcon from "./return_icon"

type Props = {
  icon: string;
};

export default function ButtonIcons(props: Props) {
  switch (props.icon) {
    case "add-circle":
      return <AddCircleIcon />;
    case "settings":
      return <SettingsIcon color="#292D32" opacity={1} size={18} />;
    case "trash":
      return <TrashIcon color="#707070" opacity={1} width={16} height={20} />;
    case "delete":
      return <DeleteIcon />;
    case "duplicate":
      return <DuplicateIcon />;
    case "edit":
      return <PenIcon color="#707070" opacity={1} />;
    case "pen":
      return <PenIcon color="white" opacity={1} />;
    case "add":
      return <UsersIcon color="#707070" opacity={1} />;
    case "users":
      return <UsersIcon color="#fff" opacity={1} />;
    case "user":
      return <AccountIcon2 />;
    case "upload":
      return <UploadIcon />;
    case "download":
      return <DownloadIcon />; 
        case "document":
      return <DocumentIcon />;
    case "category":
      return <CategoryIcon />;
    case "filter":
      return <FilterIcon />;
    case "close":
      return <CloseIcon />;
    case "arrow":
      return <ArrowCircleIcon />;
    case "arrow-left":
      return <ArrowLeftSmall />;
    case "arrow-left-default":
      return <ArrowLeftDefault />;
    case "arrow-right-default":
      return <ArrowRightDefault />;
    case "arrow-right":
      return <ArrowRightSmall />;
    case "arrow-next":
      return <ArrowNext />;
    case "info":
      return <InfoIcon />;
    case "print":
      return <PrintIcon />;
    case "check":
      return <CheckIcon />;
    case "calendar":
      return <CalendarIcon />;
    case "menu":
      return <MenuDotIcon />;
    case "SelectIcon":
      return <SelectIcon color="#fff" />;
    case "loader":
      return <Loader width="20px" height="20px" filledColor="#2196F3" />;
    case "camera":
      return <CameraIcon color="#fff" opacity="1" />;
    case "add-default":
      return <AddDefaultIcon />;
    case "return-page":
      return <ReturnIcon />;
    default:
      <AddCircleIcon />;
  }
}
