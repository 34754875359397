import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// custom components
import PageContent from "../../../components/pageContent";
import CustomButton from "../../../components/custom-button";
import CommonModal from "../../../components/common-modal";

// third-party components
import Calendar from "react-calendar";
import {
  Badge,
  Card,
  Flex,
  Heading,
  Loader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Text,
  View,
} from "@aws-amplify/ui-react";

// icons
import NavigateIcon from "../../../components/icons/navigate-icon";
import ErrorSmallIcon from "../../../components/icons/error-small-icon";

// utils
import formatDate from "../../../utils/formatDate";

// api
import { EqApi } from "../../../api/eq";
import convertStatus from "../../../utils/geStatus";
import getBadgeColor from "../../../utils/getBadgeColor";

// custom css
import "../../../styles/eq.scss";
import "../../../styles/calendar.scss";

//contexts
import { AuthorityContext } from "../../../App";

import moment from 'moment';

export default function EqScheduleIndexPage() {
  const location = useLocation();
  const { state } = location;
  const { settings, company }: any = useContext(AuthorityContext);
  const api = EqApi();
  const navigate = useNavigate();
  const { equipmentId } = useParams();
  const currentDate = new Date();
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(
    String(currentDate.getMonth()+1).length === 1
      ? "0" + (currentDate.getMonth() + 1)
      : currentDate.getMonth() + 1
  );
  // const pageTitle = localStorage.getItem('currentPage') ?? ''
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUneditableModalOpen, setIsUneditableModalOpen] = useState(false);
  const [isModalDatesOpen, setIsModalDatesOpen] = useState(false);
  const [calendarDetails, setCalendarDetails] = useState({} as any);
  const [companyName, setCompanyName] = useState("");
  const [typeOfSchedule, setTypeOfSchedule] = useState("");
  // const [disableButton, setDisableButton] = useState(false)
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(true);
  const [eqStatus, setEqStatus] = useState("");
  const [historyList, setHistoryList] = useState([] as any);
  const fetchError = false;
  const defaultButtonProps = {
    text: "",
    type: "primary",
    iconPosition: "left",
    iconName: "",
    size: "",
    disabled: false,
    click: () => {
      return "";
    },
  };
  const breadCrumbsItems = [
    { href: "/", label: "ホーム" },
    { href: `/eq/list`, label: "機材管理" },
    { href: `/eq/list`, label: "機材一覧" },
    { href: "", label: "機材利用予定一覧" },
  ];
  let eventListnerAdded: boolean = false;
  const [scheduleDataToNextPage, setScheduleDataToNextPage] = useState({});
  const [showEditResultInModal, setShowEditResultInModal] = useState(false);
  const [showEditScheduleInModal, setShowEditScheduleInModal] = useState(false);

  const openModal = (name: string, stringifiedData: string) => {
    let scheduleDataToNextPageToSet: any = {};
    scheduleDataToNextPageToSet = JSON.parse(stringifiedData);
    if (
      scheduleDataToNextPageToSet?.status_management === "in_use" ||
      scheduleDataToNextPageToSet?.actual_return
    ) {
      setShowEditResultInModal(true);
      setShowEditScheduleInModal(false);
    } else {
      setShowEditResultInModal(false);
      setShowEditScheduleInModal(true);
    }
    setScheduleDataToNextPage(scheduleDataToNextPageToSet);
    setCompanyName(name);
    setIsModalOpen(true);
    return {};
  };

  const openUneditableModal = (name: string) => {
    setScheduleDataToNextPage({});
    setCompanyName(name);
    setIsUneditableModalOpen(true);
    return {};
  };

  const openDatesModal = (typeOfSchedule: string) => {
    setTypeOfSchedule(typeOfSchedule);
    setIsModalDatesOpen(true);
  };

  const navigateToEqIndex = () => {
    navigate(`/eq/${equipmentId}`);
  };

  const getCalendarDetails = async (date: string) => {
    setLoader(true);
    try {
      const { data } = await api.getEqScheduleDetail(
        equipmentId as string,
        String(date)
      );
      if (data?.success) {
        setLoader(false);
        setHasError(false);
        setErrorMessage("");
        setCalendarDetails(data?.data);
        const dates = data?.data?.equipment_status;
        
        //group
        const groupByScheduleId = (objects: { [key: string]: any }) => {
          const groupedObjects: { [key: string]: string[] } = {};
        
          for (const key in objects) {
            const object = objects[key];
            const scheduleId = object.schedule_id;
            if (scheduleId!== null) {
              if (!groupedObjects[scheduleId]) {
                groupedObjects[scheduleId] = [];
              }
              groupedObjects[scheduleId].push(key);
            }
          }
        
          return groupedObjects;
        };

        const groupedSchedules = groupByScheduleId(dates)

        Object.keys(dates).map((item: any, i: any) => {
          const { date, company_name, status, company_id, schedule_id, actual_return, actual_delivery, delivery_schedule, return_schedule } = dates[item];
          const toJp = new Date(date).toLocaleDateString("ja-JP");
          const split = toJp.split("/");
          const formattedDate =
            split[0] + "年" + split[1] + "月" + split[2] + "日";
          setTimeout(() => {
            const allElement = document.querySelectorAll(
              `.react-calendar__tile abbr`
            );
            let schedClass = ''
            
            if(schedule_id && groupedSchedules[schedule_id].length >= 1) {
              const today = moment(date, 'YYYY-MM-DD');
              const deliverSched = moment(delivery_schedule, 'YYYY-MM-DD HH:mm:ss')
              const returnSched = moment(return_schedule, 'YYYY-MM-DD HH:mm:ss')

              if(today.isSame(deliverSched)) {
                schedClass = 'first-event'
              }

              if(today.isSame(returnSched)) {
                schedClass = 'last-event'
              }

              if(deliverSched.isSame(returnSched)) {
                schedClass = ''
              }

              if(today.isBetween(deliverSched, returnSched)) {
                schedClass = 'mid-event'
              }
            }
            
            const hasAuth = company?.admin_flag === true || (settings && 
              settings[data?.data?.site_id] && 
              settings[data?.data?.site_id]["record_control-equipments_schedule_manage"] && 
              settings[data?.data?.site_id]["record_control-equipments_schedule_manage"].includes(company_id))
            const hasAuthClass = hasAuth ? 'has-auth' : ''

            // new bg colors
            let newBgClass = ''
            if(hasAuth && schedule_id) {
              if (actual_delivery && actual_return) {
                newBgClass = 'color-return'
              } else if(actual_delivery && !actual_return) {
                newBgClass = 'color-in_use'
              } else if (!actual_delivery) {
                newBgClass = 'color-in_schedule'
              }
            }

            for (let i = 0; i <= allElement.length; i++) {
              if (allElement[i] !== undefined) {
                const parent = allElement[i].parentElement;
                if (
                  formattedDate === allElement[i].getAttribute("aria-label")
                ) {

                  if(eqStatus === '返却済') {
                    parent?.setAttribute('class', 'react-calendar__tile react-calendar__month-view__days__day returned-status')
                  }
                  
                  if (status) {
                    allElement[i]?.setAttribute("data-company", company_name);
                    allElement[i]?.setAttribute("data-company_id", company_id);
                    allElement[i]?.setAttribute(
                      "data-all-stringified",
                      JSON.stringify(dates[item])
                    );
                    allElement[i]?.setAttribute("class", `has-event ${schedClass} ${hasAuthClass} ${newBgClass}`);
                    parent?.setAttribute('class', 'react-calendar__tile react-calendar__month-view__days__day')
                  }
                }
                if (!eventListnerAdded) {
                  parent?.addEventListener("click", () => {
                    if (allElement[i].classList.contains("has-event")) {
                      let company_id: string =
                        allElement[i]?.getAttribute("data-company_id") ?? "";
                      if (
                        company?.admin_flag === true ||
                        (settings &&
                          settings[data?.data?.site_id] &&
                          settings[data?.data?.site_id][
                            "record_control-equipments_schedule_manage"
                          ] &&
                          settings[data?.data?.site_id][
                            "record_control-equipments_schedule_manage"
                          ].includes(company_id))
                      ) {
                        openModal(
                          allElement[i]?.getAttribute("data-company") ?? "",
                          allElement[i]?.getAttribute("data-all-stringified") ??
                            ""
                        );
                      } else {
                        openUneditableModal(
                          allElement[i]?.getAttribute("data-company") ?? ""
                        );
                      }
                    } else {
                      navigate(`/eq/schedule/regist/${equipmentId}`, {
                        state: {
                          pageTitle:
                            data?.data.equipment_common_name ??
                            data?.data.equipment_name ??
                            "",
                          category: data?.data.equipment_category1_name,
                          defaultDate: allElement[i].getAttribute("aria-label"),
                          onsite_manage_no: data?.data.onsite_manage_no,
                          siteId: data?.data?.site_id,
                        },
                      });
                    }
                  });
                }
              }
            }
            eventListnerAdded = true;
          }, 1000);

          return item;
        });
      } else {
        setHasError(true);
        setErrorMessage(data?.error?.message ?? data?.message);
      }
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  type calendar = {
    action: any;
  };

  const onCalendarChange = ({ action }: calendar) => {
    if (action === "next") {
      const incrementMonth = Number(currentMonth) + 1;
      const checkMonth =
        incrementMonth === 13 ? incrementMonth - 12 : incrementMonth;
      const formatMonth =
        String(checkMonth).length === 1 ? "0" + checkMonth : checkMonth;
      const formatYear = incrementMonth === 13 ? currentYear + 1 : currentYear;
      setCurrentMonth(formatMonth);
      setCurrentYear(formatYear);
      setTimeout(() => {
        getCalendarDetails(formatYear + "" + formatMonth);
      }, 1000);
    } else {
      const incrementMonth = Number(currentMonth) - 1;
      const checkMonth =
        incrementMonth === 0 ? incrementMonth + 12 : incrementMonth;
      const formatMonth =
        String(checkMonth).length === 1 ? "0" + checkMonth : checkMonth;
      const formatYear = incrementMonth === 0 ? currentYear - 1 : currentYear;
      setCurrentMonth(formatMonth);
      setCurrentYear(formatYear);
      setTimeout(() => {
        getCalendarDetails(formatYear + "" + formatMonth);
      }, 1000);
    }
  };

  const getStatus = async () => {
    setLoader(true);
    const { data } = await api.getEqStatus(equipmentId ?? "");
    try {
      if (data?.success) {
        const stat = convertStatus(data?.data?.status);
        setEqStatus(stat);
        setLoader(false);
      } else {
        const stat = convertStatus("in_use");
        setEqStatus(stat);
      }
    } catch (err) {
      return err;
    }
  };

  const getInventoryHistory = async () => {
    const { data } = await api.getEqInventoryHistory(equipmentId || "");
    try {
      if (data?.success) {
        setHistoryList(data?.data);
      }
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    getStatus();
    //getCalendarDetails(currentYear + "" + currentMonth);
    // getInventoryHistory();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(eqStatus !== ""){
      getCalendarDetails(currentYear + "" + currentMonth);
      console.log(currentYear + "" + currentMonth)
      getInventoryHistory();

    }
  }, [eqStatus])

  return (
    <PageContent
      breadcrumbs={breadCrumbsItems}
      title={
        calendarDetails?.equipment_common_name ||
        calendarDetails?.equipment_name ||
        ""
      }
    >
      {hasError ? (
        <Flex className="signin-error-message">
          <View className="error-icon">
            <ErrorSmallIcon fill="#D32F2F" />
          </View>
          <Text>{errorMessage}</Text>
        </Flex>
      ) : null}
      {!loader ? (
        <>
          <Flex className="eq-schedule-cards" direction={{'base': 'column', 'medium': 'row'}}>
            <Card textAlign={"center"} display={{'base': 'flex', 'medium': 'block'}} padding={{'base': '8px', 'medium': '20px'}}>
              <Heading level={6} width={{'base': '50%', 'medium': 'auto'}}>受入予定日</Heading>
              <View as="span" width={{'base': '50%', 'medium': 'auto'}} marginTop={{'base': '0', 'medium': '15px'}}>
                {formatDate("dot", calendarDetails?.delivery_schedule)}
              </View>
            </Card>
            <Card textAlign={"center"} display={{'base': 'flex', 'medium': 'block'}} padding={{'base': '8px', 'medium': '20px'}}>
              <Heading level={6} width={{'base': '50%', 'medium': 'auto'}}>受入日</Heading>
              <View as="span" width={{'base': '50%', 'medium': 'auto'}} marginTop={{'base': '0', 'medium': '15px'}}>
                {formatDate("dot", calendarDetails.result_delivery_schedule)}
              </View>
            </Card>
            <Card textAlign={"center"} display={{'base': 'flex', 'medium': 'block'}} padding={{'base': '8px', 'medium': '20px'}}>
              <Heading level={6} width={{'base': '50%', 'medium': 'auto'}}>返却予定日</Heading>
              <View as="span"width={{'base': '50%', 'medium': 'auto'}} marginTop={{'base': '0', 'medium': '15px'}}>
                {formatDate("dot", calendarDetails.return_schedule)}
              </View>
            </Card>
            <Card textAlign={"center"} display={{'base': 'flex', 'medium': 'block'}} padding={{'base': '8px', 'medium': '20px'}}>
              <Heading level={6} width={{'base': '50%', 'medium': 'auto'}}>返却日</Heading>
              <View as="span"width={{'base': '50%', 'medium': 'auto'}} marginTop={{'base': '0', 'medium': '15px'}}>
                {formatDate("dot", calendarDetails.result_return_schedule)}
              </View>
            </Card>
            <Card
              textAlign={"center"}
              onClick={() => openDatesModal("inventories")}
              display={{'base': 'flex', 'medium': 'block'}} padding={{'base': '8px', 'medium': '20px'}}
            >
              <Heading level={6} width={{'base': '50%', 'medium': 'auto'}}>最終棚卸日</Heading>
              <View as="span"width={{'base': '50%', 'medium': 'auto'}} marginTop={{'base': '0', 'medium': '15px'}}>
                {formatDate("dot", calendarDetails.inventory_datetime)}
              </View>
              <View className="nav-icon" top={{'base': '0', 'medium': 'auto'}} bottom={{'base': '0', 'medium': '10px'}} margin={{'base': 'auto', 'medium': '0'}} height={'18px'}>
                <NavigateIcon />
              </View>
            </Card>
          </Flex>
           {/* ボタンコンテナ */}
           <Flex justifyContent="flex-start" alignItems={'center'} marginTop={{'base':'0px','medium':'30px'}} marginBottom={{"base":"20px","medium":"0px"}}>
                  <CustomButton
                    {...defaultButtonProps}
                    text="機材一覧に戻る"
                    type="return"
                    iconName="return-page"
                    click={() => navigate(`/eq/list`, { state: { preserveSearch: true } })}
                  />
          </Flex>
          <View className="custom-table" marginTop={{'base':'0px','medium':'0px'}}>
            <Flex gap={1} alignItems={'flex-end'} direction={{'base': 'column', 'medium': 'row'}}>
              <Table width={{'base': '100%', 'medium': '70%'}}>
                <TableBody>
                  <TableRow className="eq-index--header">
                    <TableCell colSpan={2}>
                      <Flex wrap={{'base': 'wrap', 'medium': 'no-wrap'}} alignItems={'center'}>
                        <View as="div" width={{'base': '100%', 'medium': '118px'}}>
                          <View as="span" className="eq-index--sub-title" display={'inline-block'}>
                            {calendarDetails.equipment_category1_name}
                          </View>
                        </View>
                        <Heading level={5} flex={1}>
                          {calendarDetails.onsite_manage_no || '-'}
                        </Heading>
                        <View minWidth={'110px'}>
                          <Badge
                            backgroundColor={getBadgeColor(eqStatus).bg}
                            color={getBadgeColor(eqStatus).color}
                          >
                            {eqStatus}
                          </Badge>
                        </View>
                      </Flex>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <View width={{'base': '85%', 'medium': '30%'}} margin={{'base': '0 auto', 'medium': 'auto'}}>
                <View role="tablist" className="amplify-tabs__list" width={'100%'}>
                  <button
                    role="tab"
                    id="Tab1-tab"
                    aria-selected="true"
                    aria-controls="Tab1-panel"
                    className="amplify-tabs__item"
                    onClick={() => navigateToEqIndex()}
                  >
                    機材情報
                  </button>
                  <button
                    role="tab"
                    id="Tab2-tab"
                    aria-selected="false"
                    aria-controls="Tab2-panel"
                    className="amplify-tabs__item amplify-tabs__item--active"
                  >
                    スケジュール<br />（予定/実績）
                  </button>
                </View>
              </View>
            </Flex>
            <Calendar
              onActiveStartDateChange={onCalendarChange}
              value={currentDate}
              defaultActiveStartDate={
                new Date(currentYear, Number(currentMonth) - 1, 1)
              }
              locale="ja"
            />
          </View>
        </>
      ) : (
        <View className="table-loader">
          <Loader width="5rem" height="5rem" filledColor="#2196F3" />
        </View>
      )}
      {fetchError && (
        <Text className="error-fetch-message">
          Error in fetching data. Please contact administrator.
        </Text>
      )}
      <Flex 
        marginTop={'20px'} 
        gap={'10px'} 
        justifyContent={{ 'base': 'space-between', 'medium': 'flex-end' }} 
        direction={{ 'base': 'row', 'medium': 'row' }} 
        width={{ 'base': '100%', 'medium': '100%' }} 
        wrap={{ 'base': 'wrap', 'medium': 'wrap' }}
      >
        {/* 他の要素コンテナ */}
        <Flex justifyContent="flex-end" gap="10px" wrap="wrap" >
          <Flex>
            <View as="div" marginBottom={'8px'} width={{ 'base': '90px', 'medium': '100px' }}>
              <Flex gap={'5px'} alignItems={'center'}>
                <View as="div" width={'24px'} height={'24px'} borderRadius={'4px'} backgroundColor={'rgb(218, 231, 252)'}></View>
                <Text fontSize={{ 'base': '12px', 'medium': '12px' }} flex={1}>：予約可能</Text>
              </Flex>
            </View>
            <View as="div" marginBottom={'8px'} width={{ 'base': '80px', 'medium': '90px' }}>
              <Flex gap={'5px'} alignItems={'center'}>
                <View as="div" width={'24px'} height={'24px'} borderRadius={'4px'} backgroundColor={'rgb(71, 149, 236)'}></View>
                <Text fontSize={{ 'base': '12px', 'medium': '12px' }} flex={1}>：未使用</Text>
              </Flex>
            </View>
            <View as="div" marginBottom={'8px'} width={{ 'base': '80px', 'medium': '90px' }}>
              <Flex gap={'5px'} alignItems={'center'}>
                <View as="div" width={'24px'} height={'24px'} borderRadius={'4px'} backgroundColor={'rgb(247, 227, 216)'}></View>
                <Text fontSize={{ 'base': '12px', 'medium': '12px' }} flex={1}>：使用中</Text>
              </Flex>
            </View>
          </Flex>
          <Flex>
            <View as="div" marginBottom={'8px'} width={{ 'base': '90px', 'medium': '90px' }}>
              <Flex gap={'5px'} alignItems={'center'}>
                <View as="div" width={'24px'} height={'24px'} borderRadius={'4px'} backgroundColor={'rgb(73, 104, 214)'}></View>
                <Text fontSize={{ 'base': '12px', 'medium': '12px' }} flex={1}>：使用済</Text>
              </Flex>
            </View>
            <View as="div" marginBottom={'8px'} width={{ 'base': '177px', 'medium': '140px' }}>
              <Flex gap={'5px'} alignItems={'center'}>
                <View as="div" width={'24px'} height={'24px'} borderRadius={'4px'} backgroundColor={'#707070'}></View>
                <Text fontSize={{ 'base': '12px', 'medium': '12px' }} flex={1}>：予約/操作不可</Text>
              </Flex>
            </View>
          </Flex>
        </Flex>
      </Flex>
      <CommonModal
        isOpen={isUneditableModalOpen}
        onClose={() => setIsUneditableModalOpen(false)}
        textHeading={`${companyName}の使用予定があります。`}
        //textOperation={`${ companyName }が使用しました`}
      >
        <View
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <CustomButton
            {...defaultButtonProps}
            text="閉じる"
            type="default"
            iconName="close"
            iconPosition="left"
            size="large"
            click={() => setIsUneditableModalOpen(false)}
          />
        </View>
      </CommonModal>
      <CommonModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        textHeading="使用予定"
        textOperation={`${companyName}の使用予定があります。`}
      >
        <CustomButton
          {...defaultButtonProps}
          text="閉じる"
          type="default"
          iconName="close"
          iconPosition="left"
          size="large"
          click={() => setIsModalOpen(false)}
        />
        {showEditScheduleInModal && (
          <CustomButton
            {...defaultButtonProps}
            text="使用予定編集"
            type="primary"
            iconName="pen"
            iconPosition="left"
            size="large"
            click={() => {
              navigate(`/eq/schedule/edit/${equipmentId}`, {
                state: {
                  pageTitle:
                    calendarDetails?.equipment_common_name ||
                    calendarDetails?.equipment_name ||
                    "",
                  scheduleDataToNextPage: scheduleDataToNextPage,
                  siteId: calendarDetails.site_id,
                  equipment_category1_name:
                    calendarDetails.equipment_category1_name,
                  onsite_manage_no: calendarDetails.onsite_manage_no,
                  model: calendarDetails.model,
                },
              });
            }}
          />
        )}
        {showEditResultInModal && (
          <CustomButton
            {...defaultButtonProps}
            text="使用実績編集"
            type="primary"
            iconName="pen"
            iconPosition="left"
            size="large"
            click={() =>
              navigate(`/eq/schedule/ondate/edit/${equipmentId}`, {
                state: {
                  pageTitle:
                    calendarDetails?.equipment_common_name ||
                    calendarDetails?.equipment_name ||
                    "",
                  scheduleDataToNextPage: scheduleDataToNextPage,
                  siteId: calendarDetails.site_id,
                  equipment_category1_name:
                    calendarDetails.equipment_category1_name,
                  onsite_manage_no: calendarDetails.onsite_manage_no,
                  model: calendarDetails.model,
                },
              })
            }
          />
        )}
      </CommonModal>
      <View className="schedule-dates-modal">
        <CommonModal
          isOpen={isModalDatesOpen}
          onClose={() => setIsModalDatesOpen(false)}
        >
          <Flex className="schedule-dates-modal--contents">
            <View className="custom-table custom-table__eq">
              {typeOfSchedule === "schedule" && (
                <>
                  <Heading className="schedule-dates-modal--title">
                    搬入スケジュール
                  </Heading>
                  <Table highlightOnHover={true}>
                    <TableBody>
                      <TableRow>
                        <TableCell as="th">日付</TableCell>
                        <TableCell as="th">時間</TableCell>
                        <TableCell as="th">確認者（所属）</TableCell>
                        <TableCell as="th">場所</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {formatDate(
                            "dot",
                            calendarDetails?.inventory_datetime
                          )}
                        </TableCell>
                        <TableCell>
                          {formatDate(
                            "time",
                            calendarDetails?.inventory_datetime
                          )}
                        </TableCell>
                        <TableCell>建設　太郎（Z建設）</TableCell>
                        <TableCell>東館1F</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {formatDate(
                            "dot",
                            calendarDetails?.inventory_datetime
                          )}
                        </TableCell>
                        <TableCell>
                          {formatDate(
                            "time",
                            calendarDetails?.inventory_datetime
                          )}
                        </TableCell>
                        <TableCell>建設　太郎（Z建設）</TableCell>
                        <TableCell>東館1F</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {formatDate(
                            "dot",
                            calendarDetails?.inventory_datetime
                          )}
                        </TableCell>
                        <TableCell>
                          {formatDate(
                            "time",
                            calendarDetails?.inventory_datetime
                          )}
                        </TableCell>
                        <TableCell>建設　太郎（Z建設）</TableCell>
                        <TableCell>東館1F</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {formatDate(
                            "dot",
                            calendarDetails?.inventory_datetime
                          )}
                        </TableCell>
                        <TableCell>
                          {formatDate(
                            "time",
                            calendarDetails?.inventory_datetime
                          )}
                        </TableCell>
                        <TableCell>建設　太郎（Z建設）</TableCell>
                        <TableCell>東館1F</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </>
              )}
              {typeOfSchedule === "return" && (
                <>
                  <Heading className="schedule-dates-modal--title">
                    返却スケジュール
                  </Heading>
                  <Table highlightOnHover={true}>
                    <TableBody>
                      <TableRow>
                        <TableCell as="th">日付</TableCell>
                        <TableCell as="th">時間</TableCell>
                        <TableCell as="th">確認者（所属）</TableCell>
                        <TableCell as="th">場所</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {formatDate(
                            "dot",
                            calendarDetails?.inventory_datetime
                          )}
                        </TableCell>
                        <TableCell>
                          {formatDate(
                            "time",
                            calendarDetails?.inventory_datetime
                          )}
                        </TableCell>
                        <TableCell>建設　太郎（Z建設）</TableCell>
                        <TableCell>東館1F</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {formatDate(
                            "dot",
                            calendarDetails?.inventory_datetime
                          )}
                        </TableCell>
                        <TableCell>
                          {formatDate(
                            "time",
                            calendarDetails?.inventory_datetime
                          )}
                        </TableCell>
                        <TableCell>建設　太郎（Z建設）</TableCell>
                        <TableCell>東館1F</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {formatDate(
                            "dot",
                            calendarDetails?.inventory_datetime
                          )}
                        </TableCell>
                        <TableCell>
                          {formatDate(
                            "time",
                            calendarDetails?.inventory_datetime
                          )}
                        </TableCell>
                        <TableCell>建設　太郎（Z建設）</TableCell>
                        <TableCell>東館1F</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {formatDate(
                            "dot",
                            calendarDetails?.inventory_datetime
                          )}
                        </TableCell>
                        <TableCell>
                          {formatDate(
                            "time",
                            calendarDetails?.inventory_datetime
                          )}
                        </TableCell>
                        <TableCell>建設　太郎（Z建設）</TableCell>
                        <TableCell>東館1F</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </>
              )}
              {typeOfSchedule === "inventories" && (
                <>
                  <Heading className="schedule-dates-modal--title">
                    棚卸履歴
                  </Heading>
                  <Table highlightOnHover={true} minWidth={'600px'} overflow={{'base': 'scroll', 'medium': 'unset'}}>
                    <TableBody>
                      <TableRow>
                        <TableCell as="th">日付</TableCell>
                        <TableCell as="th">時間</TableCell>
                        <TableCell as="th">確認者（所属）</TableCell>
                        <TableCell as="th">場所</TableCell>
                      </TableRow>
                      {historyList.length > 0 &&
                        historyList.map((item: any, index: number) => {
                          return (
                            <>
                              <TableRow key={index}>
                                <TableCell>
                                  {formatDate("dot", item?.inventory_datetime)}
                                </TableCell>
                                <TableCell>
                                  {formatDate("time", item?.inventory_datetime)}
                                </TableCell>
                                {/*bellow code, to get conpany_name, user_company_id → company_name. Company_id = user_company_id, in current implementation 20240703 */}
                                <TableCell>{`${item.user_name}(${item.company_name})`}</TableCell>
                                <TableCell>
                                  {item.site_construction_location_name}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                    </TableBody>
                  </Table>
                </>
              )}
            </View>
            <CustomButton
              {...defaultButtonProps}
              text="閉じる"
              type="default"
              iconName="close"
              iconPosition="left"
              size="large"
              click={() => setIsModalDatesOpen(false)}
            />
          </Flex>
        </CommonModal>
      </View>
    </PageContent>
  );
}
